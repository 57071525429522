<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-row
      v-if="adjustmentData"
      class="print-container"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card>
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-dark ml-0"
          >
            <b-tab active>
              <template #title>
                <span>{{ $t('Adjustment') }}</span>
              </template>

              <section class="invoice-preview-wrapper">

                <b-row
                  class="invoice-preview"
                >
                  <b-col
                    cols="12"
                  >
                    <div
                      class="invoice-preview-card"
                    >
                      <div class="mt-1">
                        <div class="d-flex justify-content-center bd-highlight">
                          <div class="bd-highlight text-center">
                            <b-img
                              :src="configs.business_logo"
                              width="128"
                            />
                            <h3 class="mb-0">
                              {{ configs.business_name }}
                            </h3>
                            <p class="mb-1">
                              {{ configs.business_address }}. {{ configs.business_phone }}, {{ configs.business_email }}, {{ configs.business_website }}
                            </p>
                          </div>
                        </div>

                        <!-- Spacer -->
                        <hr class="invoice-spacing">
                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Reason') }}: </span>{{ adjustmentData.reason.name }}
                          </div>
                        </div>
                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Invoice') }}: </span>#{{ adjustmentData.id }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="float-right"><span class="text-dark font-weight-bold">{{ $t('Date') }}: </span>{{ adjustmentData.adjustment_date }}</span>
                          </div>
                        </div>
                        <div class="d-flex bd-highlight border-bottom">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark mb-2 font-weight-bold">{{ $t('Branch') }}: </span>{{ adjustmentData.branch_name }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="float-right"><span class="text-dark font-weight-bold">{{ $t('Reference') }}: </span>{{ adjustmentData.reference }}</span>
                          </div>
                        </div>

                        <!-- Invoice Description: Table -->
                        <b-table-lite
                          class="mt-1"
                          hover
                          bordered
                          responsive
                          :items="adjustmentData.inventory_adjustment_items"
                          :fields="[{ label: '#', key: 'sr'}, { label: $t('Item'), key: 'item'}, { label: $t('Price'), key: 'price', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Qty'), key: 'quantity', tdClass: 'text-right', thClass: 'text-right'},, { label: $t('Unit'), key: 'unit', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Amount'), key: 'amount', tdClass: 'text-right', thClass: 'text-right'}]"
                        >
                          <!-- Column: Price -->
                          <template #cell(price)="data">
                            {{ data.item.price.toLocaleString() }}
                          </template>
                          <!-- Column: Amount -->
                          <template #cell(amount)="data">
                            {{ data.item.amount.toLocaleString() }}
                          </template>
                        </b-table-lite>

                        <div class="d-flex flex-row-reverse bd-highlight mb-1">
                          <div class="bd-highlight w-120px text-right pr-2rem font-weight-bold">
                            {{ adjustmentData.total_amount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-120px font-weight-bold">
                            {{ $t('Total Amount') }}:
                          </div>
                        </div>

                      </div>

                    </div>
                  </b-col>
                </b-row>

              </section>
            </b-tab>
          </b-tabs>

        </b-card>

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        xl="3"
        md="4"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-1"
            />
            {{ $t('Print') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            :to="{ name: 'adjustment-edit', params: { id: adjustmentData.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-1"
            />
            {{ $t('Edit') }}
          </b-button>

          <!-- Button: Close -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="close"
          >
            <feather-icon
              icon="StopCircleIcon"
              class="mr-1"
            />
            {{ $t('Close') }}
          </b-button>

        </b-card>
      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BButton, BTabs, BTab, BTableLite, BImg,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import adjustmentStoreModule from '../adjustmentStoreModule'
import configData from '../../../common/configModule'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableLite,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    close() {
      this.$router.push({ name: 'adjustment-list' })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'adjustment'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, adjustmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const adjustmentData = ref({
      adjustment_date: null,
      reason: [],
      branch_name: '',
      total_amount: 0,
      reference: '',
      transaction_type: '',
      inventory_adjustment_items: [],
    })
    const router = context.root.$router
    const adjustmentId = router.currentRoute.params.id

    store.dispatch('adjustment/fetchOne', { id: adjustmentId })
      .then(response => {
        const dataItems = []
        const responseItems = response.data.data.inventory_adjustment_items
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            sr: (i + 1),
            item_id: responseItems[i].item_id,
            item: responseItems[i].item.name,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            unit: responseItems[i].item.unit,
            amount: responseItems[i].amount,
          })
        }

        const responseData = {
          id: response.data.data.id,
          adjustment_date: response.data.data.adjustment_date,
          reason: response.data.data.reason,
          branch_name: response.data.data.branch.name,
          total_amount: response.data.data.total_amount,
          reference: response.data.data.reference,
          inventory_adjustment_items: dataItems,
        }
        adjustmentData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          adjustmentData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    const { configs } = configData()

    return {
      adjustmentData,
      printInvoice,
      configs,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-dark {
  color: #000000;
}
.w-120px {
  width: 120px;
}
.pr-2rem {
  padding-right: 2rem !important;
}
.tabStyle {
  color : black !important;
}
[dir] .nav-tabs .nav-link {
  padding-left: 0;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body, h3 {
    background-color: transparent !important;
    font-size: 27px;
    font-family: 'Pyidaungsu' !important;
    color: #000000 !important;
  }
  p {
    line-height: 2.5rem !important;
  }
  img {
    width: 164px;
  }
  .table thead th, .table tfoot th {
    font-size: 27px;
  }
  .w-120px {
    width: 160px;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #000 !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .print-container {
    > [class*="col-"] {
      flex: 0 0 100%;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  [dir] .card {
    border: none !important;
  }
  [dir] .card-body {
    padding: 0;
  }
  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
